<template> 
    <Provider v-bind="provider_info"/>
</template>

<script>
import Provider from "@/components/Provider.vue";
export default {
    name: "Kacie Kopf",
    components: {
        Provider
    },
    data() {
        return {
            provider_info: {
                name: "Kacie Kopf",
                img_src: "photo-coming-soon.jpg",
                undergrad: "Bryan College of Health Sciences",
                undergrad_year: 1981,
                professional_school: "University of Nebraska Medical Center",
                professional_school_year: 1988,
                biography: [ 
                    "Kacie grew up just outside of Stuart, NE and graduated from Stuart High School. She attended Bryan College of Health Sciences and graduated with her Bachelors of Science in Nursing and then went on to receive her Masters of Science in Nursing from Creighton University in 2013. She is certified with the American Academy of Nurse Practitioners as a Family Nurse Practitioner. She has been working as a Family Nurse Practitioner for the last 7 years with the last 4 years at Shenandoah Medical Center in Shenandoah, Iowa.",
                    "Kacie is married to Logan and they have two girls, Lennie and Collins. She enjoys outdoor activities with her family and friends, gardening, and baking.",
                    "Kacie enjoys rural medicine and treating patients of all ages with acute and chronic conditions. She is passionate about educating patients with an emphasis on preventive medicine.",
                ],
                certifications: [
                    "Advanced Cardiac Life Support (ACLS)",
                    "Basic Life Support (BLS)",
                    "Pediatric Advanced Life Support (PALS)",
                    "FMCSA Certified Medical Examiner (DOT exams)"
                ],
                acceptsNewPatients: true,
                telemedicine: true
            }
        }
    }
}
</script>

<style scoped>

</style>